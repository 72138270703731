import Component from '../core/Component'
import EventBus from '../core/EventBus'
import GoogleMap from './GoogleMap'

export default class FilterMap extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            checkboxs: [],
            searchInput: null,
            locateMe: null,
        }

        this.ref.checkboxes = this.ref.checkboxs
        delete this.ref.checkboxs

        this.items = {}
    }

    prepare() {
        this.element.addEventListener('change', this.handleChange)
        this.element.addEventListener('submit', this.handleSubmit)
        //this.ref.locateMe.addEventListener("click",::this.locateMe)

        if (window.location.hash.match(/^#filter:/)) {
            const params = window.location.hash.split(':')[1]?.split(',')

            if (params) {
                const checkboxes = this.ref.checkboxes.reduce((acc, item) => {
                    acc[item.name] = item
                    return acc
                }, {})

                params.forEach((param) => {
                    if (param in checkboxes) {
                        checkboxes[param].checked = true
                    }
                })

                setTimeout(() => {
                    this.handleSubmit()
                }, 0)
            }
        }
    }

    destroy() {
        if (this.map) {
            this.map.destroy()
        }
        this.element.removeEventListener('submit', this.handleSubmit)
        //this.ref.locateMe.removeEventListener("click",::this.locateMe)
    }

    handleChange = (event) => {
        const hash = this.ref.checkboxes
            .filter((checkbox) => checkbox.checked)
            .map((checkbox) => checkbox.name)
            .join(',')

        window.history.replaceState('', '', hash.length ? `#filter:${hash}` : '')
    }

    handleSubmit = (event) => {
        if (event) {
            event.preventDefault()
        }

        this.items = this.ref.checkboxes.reduce((acc, checkbox) => {
            acc[checkbox.name] = checkbox.checked
            return acc
        }, {})

        this.items[this.ref.searchInput.name] = this.ref.searchInput.value

        this.value = {
            adress: this.ref.searchInput.value,
            items: this.items,
        }

        EventBus.emit('mapFilter:submit', this.value)
    }

    locateMe() {
        this.map = new GoogleMap(document.querySelector('.Map-inner'))

        let success = async (position) => {
            let adress = await _coordsToAdress(position.coords.latitude, position.coords.longitude)

            const latlng = new google.maps.LatLng(
                position.coords.latitude,
                position.coords.longitude,
            )

            adress = adress[0].formatted_address

            this.ref.searchInput.value = adress

            this.map.addOneMarker(latlng)
        }

        let _coordsToAdress = (lat, lng) => {
            let latLng = { lat: lat, lng: lng }
            return new Promise((resolve, reject) => {
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode(
                    {
                        location: latLng,
                    },
                    (results, status) => {
                        if (status == google.maps.GeocoderStatus.OK) {
                            resolve(results)
                        } else {
                            reject(status)
                            console.log(
                                'Geocode was not successful for the following reason: ' + status,
                            )
                        }
                    },
                )
            })
        }

        navigator.geolocation.getCurrentPosition(success)
    }
}
