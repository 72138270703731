import Component from '../core/Component';
import { enableScrolling, disableScrolling } from "../services/Viewport/scroll";

export default class NavOpener extends Component {
    prepare() {
        this.element.addEventListener('click',::this.handleClick)
    }

    handleClick(event) {
        event.preventDefault();

        const NAV = document.querySelector(".Navigation")
        const NAV_OVERLAY = document.querySelector(".Navigation-overlay")

        let is_active = NAV.classList.contains('is-active')

        if (!is_active) {
            disableScrolling();
        } else {
            enableScrolling();
        }
        
        NAV.classList.toggle('is-active')
        NAV_OVERLAY.classList.toggle('is-active')
    }
}