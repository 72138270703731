import Component from '../core/Component';
import { toggleClass } from '../utils/dom';

export const STATES = {
    ACTIVE: 'is-active',
    HIDDEN: 'is-hidden'
}
export default class Filter extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            switchs: [],
            contents: [],
            links: [],
            items: []
        }

        this.isOpen = 'list'
    }

    prepare() {
        this.ref.switchs.forEach(item => {
            item.addEventListener("click",:: this.handleSwitch)
        })

        this.ref.links.forEach(link => {
            link.addEventListener("click",:: this.handleClick)
        })

        this.updateTab(window.location.hash)
    }

    handleClick(e) {
        this.element.classList.add(STATES.ACTIVE)
        let hash = e.currentTarget.hash

        const CURRENT_ELEMENT = this.element.querySelector("a[href='" + hash + "']")
        const SELECTED_CATEGORY = CURRENT_ELEMENT.dataset.category

        if (CURRENT_ELEMENT.classList.contains(STATES.ACTIVE)) {
            CURRENT_ELEMENT.classList.remove(STATES.ACTIVE)

            const itemInfo = this.ref.items.filter(item => item.dataset.category === SELECTED_CATEGORY)
            if (itemInfo.length > 0) {
                itemInfo[0].classList.add(STATES.HIDDEN)
                this.element.classList.remove(STATES.ACTIVE)
            }

        } else {
            this.show(hash)
        }
    }

    handleSwitch(e) {
        const target = e.currentTarget.getAttribute('data-target')

        if(target != this.isOpen){
            this.isOpen = target

            this.ref.switchs.forEach(item => {
                item.classList.toggle(STATES.ACTIVE)
            })

            this.ref.contents.forEach(item => {
                item.classList.toggle(STATES.ACTIVE)
            })
        }
    }

    updateTab(hash) {
        let ids = this.ref.links.map((link) => {
            return link.hash
        })

        if (ids.indexOf(hash) !== -1) {
            this.show(hash)
        }
    }

    show(hash) {
        const CURRENT_ELEMENT = this.element.querySelector("a[href='" + hash + "']")
        const SELECTED_CATEGORY = CURRENT_ELEMENT.dataset.category

        this.ref.links.forEach(link => {
            link.classList.remove(STATES.ACTIVE)
        });

        if (CURRENT_ELEMENT.classList.contains(STATES.ACTIVE)) {
            CURRENT_ELEMENT.classList.remove(STATES.ACTIVE)
        } else {
            CURRENT_ELEMENT.classList.add(STATES.ACTIVE)
        }

        this.ref.items.forEach(item => {
            if (this.element.hasAttribute("data-reverse")) {
                toggleClass(item, STATES.HIDDEN, item.dataset.category !== SELECTED_CATEGORY)
            } else {
                toggleClass(item, STATES.HIDDEN, item.dataset.category == SELECTED_CATEGORY)
            }
        });
    }
}
