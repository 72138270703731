import Carousel from './Carousel'

export default class CarouselHP extends Carousel {
    constructor(element) {
        super(element)
    }

    prepare() {
        if (this.ref.container == null) {
            this.ref.container = this.element
        }

        const items = this.ref.container.querySelectorAll(this.options.cellSelector)

        if (items.length > 3) {
            this.options.wrapAround = true
            this.options.prevNextButtons = true
        } else {
            this.options.wrapAround = false
            this.options.prevNextButtons = false
        }

        super.prepare()
    }

}
