import Component from '../core/Component'

export default class ModalOpener extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        event.preventDefault();

        let id = this.element.getAttribute('href');
        let style = this.element.getAttribute('data-style');

        if (!id) {
            return;
        }

        if(style === 'compact'){
            const modals = document.querySelector(id)
            modals.classList.add('Modal-compact')
        }

        id = id.replace('#', '');

        const target = document.getElementById(id);

        if (!target) {
            return;
        }

        const modal  = Component.getFromElement(target);

        if (modal) {
            modal.open();
        }

    }

}
