import Component from '../core/Component';

export const STATES = {
    ACTIVE: 'is-active'
}
export default class Switch extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            links: [],
            firstBlock: null,
            secondBlock: null,
        }
    }

    prepare() {
        this.ref.links.forEach(link => {
            link.addEventListener("click", this.handleClick)
        })

        this.hashCheck()
    }

    handleClick = event => {
        if(!event.target.classList.contains(STATES.ACTIVE)){
            if(event.target.getAttribute("data-target") === "firstBlock"){
                this.ref.firstBlock.classList.add(STATES.ACTIVE)
                this.ref.secondBlock.classList.remove(STATES.ACTIVE)
            } else {
                this.ref.firstBlock.classList.remove(STATES.ACTIVE)
                this.ref.secondBlock.classList.add(STATES.ACTIVE)
            }

            this.ref.links.forEach(link => {link.classList.toggle(STATES.ACTIVE)})
        }
    }

    hashCheck(){
        const hash = document.URL.split('#').pop()

        if(hash === "top"){
            this.ref.firstBlock.classList.remove(STATES.ACTIVE)
            this.ref.secondBlock.classList.add(STATES.ACTIVE)
            this.ref.links.forEach(link => {link.classList.toggle(STATES.ACTIVE)})
        }
    }
}
