import Component from '../core/Component';
import GoogleMapsLoader from 'google-maps';
import { createMarker } from './Marker';
import { GOOGLE_MAPS_API_KEY } from '../core/config'
import EventBus from '../core/EventBus'

/*změnit api key */
GoogleMapsLoader.KEY = GOOGLE_MAPS_API_KEY
GoogleMapsLoader.LIBRARIES = ['geometry', 'places'];

export default class GoogleMap extends Component {

    constructor(element, data = null) {
        super(element);

        this.markers = [];
        this.data = data

        this.google = null;

        GoogleMapsLoader.load(:: this.handleGoogleMapsLoad);

        this.infoWindow = null;
        this._onMarkerClick = id => { };

        this.isReady = false
        this.commandQueue = []
    }

    handleGoogleMapsLoad(google) {
        this.google = google;
        this.map = new google.maps.Map(this.element, {
            zoom: 8,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: [{ "featureType": "all", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "administrative.country", "elementType": "geometry.stroke", "stylers": [{ "color": "#8aa49f" }, { "visibility": "on" }] }, { "featureType": "administrative.province", "elementType": "all", "stylers": [{ "visibility": "on" }] }, { "featureType": "administrative.locality", "elementType": "all", "stylers": [{ "visibility": "on" }] }, { "featureType": "administrative.neighborhood", "elementType": "all", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape.natural", "elementType": "all", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape.natural", "elementType": "geometry", "stylers": [{ "visibility": "on" }, { "gamma": "3.32" }] }, { "featureType": "landscape.natural.landcover", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "landscape.natural.landcover", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "landscape.natural.terrain", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road", "elementType": "geometry", "stylers": [{ "visibility": "on" }] }, { "featureType": "road", "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "featureType": "transit.station", "elementType": "all", "stylers": [{ "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry", "stylers": [{ "visibility": "on" }] }]
        });

        this.map.addListener('bounds_changed', :: this.handleBoundsChange);

        if (!this.data && this.element.dataset.data) {
            this.data = JSON.parse(this.element.dataset.data)
        }

        //Autocomplete
        this.autocomplete = new google.maps.places.Autocomplete(
            (document.getElementById('autocomplete')),
            {
                types: ['(regions)'],
                componentRestrictions: {
                    country: 'cz'
                }
            }
        );

        this.show(this.data);

        this.isReady = true

        if (this.commandQueue.length) {
            let item = []
            while (item = this.commandQueue.shift()) {
                const [command, args] = item
                this[command].apply(this, args)
            }
        }
    }

    handleBoundsChange() {
        if (this.infoWindow) {
            this.infoWindow.close();
        }
    }

    show(points) {
        this.markers.forEach(marker =>{
          marker.setMap(null);
        })

        if (points.length === 0) {
            return;
        }

        const google = this.google;
        const Marker = createMarker(google)

        this.points = points;

        this.markers = this.points.map(point => {
            const latlng = new google.maps.LatLng(point.position.lat, point.position.lng);

            const marker = new Marker({
                position: latlng,
                title: point.name,
                map: this.map,
                data: {
                    ...point
                },
                onClick: marker => {
                    EventBus.emit('marker:click', {
                        marker: marker
                    })
                }
            });

            return marker;
        });

        this.render();
    }

    render() {
        if (!this.google) {
            return;
        }

        if (this.markers.length === 0) {
            return;
        }

        this.fit(this.points);
    }

    fit(points) {
        const latitudes = points.map(point => point.position.lat);
        const longitudes = points.map(point => point.position.lng);

        const minLat = Math.min(...latitudes);
        const maxLat = Math.max(...latitudes);

        const minLng = Math.min(...longitudes);
        const maxLng = Math.max(...longitudes);

        const southWest = new google.maps.LatLng(minLat + 0.3, minLng);
        const northEast = new google.maps.LatLng(maxLat - 0.15, maxLng + 0.2);

        const bounds = new google.maps.LatLngBounds(southWest, northEast);

        if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
           var extendPoint1 = new google.maps.LatLng(bounds.getNorthEast().lat() + 0.1, bounds.getNorthEast().lng() + 0.1);
           var extendPoint2 = new google.maps.LatLng(bounds.getNorthEast().lat() - 0.1, bounds.getNorthEast().lng() - 0.1);
           bounds.extend(extendPoint1);
           bounds.extend(extendPoint2);
        }

        this.map.fitBounds(bounds);


    }

    resize() {
        if (this.google) {
            this.google.maps.event.trigger(this.map, 'resize')
        }
    }


    async findArea(address, distance, items) {
        if (!this.isReady) {
            this.commandQueue.push(['findArea', [address, distance, items]])
            return
        }
        if (address) {
            this.isInArea = false
            let promise = await this._getCoordsFromAddress(address)

            let lat = promise[0].geometry.location.lat(),
                lng = promise[0].geometry.location.lng()

            const EARTH_RADIUS = 6371; // radius of earth in km
            const RADIUS = distance

            let distances = []

            this.itemsOnlyInArea = []

            items.forEach((marker,index) =>{
                let mlat = marker.position.lat,
                    mlng = marker.position.lng

                let dLat  = this._radius(mlat - lat),
                    dLong = this._radius(mlng - lng)

                let a = Math.sin(dLat/2) * Math.sin(dLat/2) +
                    Math.cos(this._radius(lat)) * Math.cos(this._radius(lat)) * Math.sin(dLong/2) * Math.sin(dLong/2);
                let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
                let d = EARTH_RADIUS * c;

                distances[index] = d;

                if (distances[index] <= RADIUS) {
                    this.isInArea = true
                    this.itemsOnlyInArea.push(marker)
                }
            })

            if (this.isInArea) {
                this.markers.forEach(marker =>{
                  marker.setMap(null);
                })

                this.show(this.itemsOnlyInArea)
            } else {
                alert("V oblasti není žádná čerpací stanice.")
            }
        } else {//když není vyplněna adresa, tak se vezmou pouze checkboxy
            this.show(items)
        }
    }

    addOneMarker(latlng) {
        const Marker = createMarker(this.google)

        const marker = new Marker({
            position: latlng,
            title: "Aktuální poloha",
            map: this.map,
            className: "Marker--current",
            template: "<span></span>"
        });
    }


    _getCoordsFromAddress(address) {
        return new Promise( (resolve, reject) => {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({
                'address': address
            }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    resolve(results);
                } else {
                    reject(status);
                    console.log("Geocode was not successful for the following reason: " + status);
                }
            });
        })
    }

    _radius(value) {
        return value * Math.PI / 180;
    }

}