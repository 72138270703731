import keycode from 'keycode'

import Component from '../core/Component'
import { loadComponents, removeComponents } from '../core/componentUtils'
import { disableScrolling, enableScrolling } from '../services/Viewport/scroll'
import support from '../utils/BrowserSupport'

export const STATES = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
}

export default class Modal extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            background: null,
            close: null,
            content: null
        }

        this.handleKeyDown = :: this.handleKeyDown
    }

    prepare() {
        this.ref.close.addEventListener('click', :: this.handleCloseClick)
        this.ref.background.addEventListener('click', :: this.handleCloseClick)

        this.element.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATES.ACTIVE)) {
                this.element.classList.remove(STATES.VISIBLE)
            }
        })
    }

    handleCloseClick(event) {
        event.preventDefault()
        this.close()
    }

    handleKeyDown(event) {
        if (keycode(event.keyCode) === 'esc') {
            this.close()
        }
    }

    open() {
        this.element.classList.add(STATES.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATES.ACTIVE)
        disableScrolling()
        document.addEventListener('keydown', this.handleKeyDown)
    }

    close() {
        this.element.classList.remove(STATES.ACTIVE)
        enableScrolling()
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    setContent(html) {
        removeComponents(this.ref.content)
        this.ref.content.innerHTML = html
        loadComponents(this.ref.content)

        return this
    }

}
