import Component from '../core/Component'
import Scroll from '../services/Scroll'

const STATUS = {
    PINNED: 'is-pinned',
}

export default class FixableHeader extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            scrollPosition: 0,
            scrollDirection: null,
        }

        this.state = {
            height: 0,
            previousScroll: 0,
            pinned: false,
        }
    }

    prepare() {
        Scroll.on('scroll', this.handleScroll)
        Scroll.on('resize', this.handleResize)
        this.resize()
        this.element.addEventListener('transitionend', this.handleTransitionEnd)
    }

    destroy() {
        Scroll.off('scroll', this.handleScroll)
        Scroll.off('resize', this.handleResize)
    }

    handleScroll = ({ offset }) => {
        this.render(offset.y)
    }

    handleTransitionEnd = (event) => {
        if (event.target !== this.element) {
            return
        }

        if (this.element.classList.contains(STATUS.VISIBLE) && !this.state.pinned) {
            this.element.classList.remove(STATUS.VISIBLE)
        }
    }

    render(y) {
        if(y > 0) {
            this.element.style.transform = `translateY(${-Math.min(y, this.state.height)}px)`
            this.element.style.transition = 'transform .45s ease-out'
        } else {
            this.element.style.transform = `translateY(0)`
        }

        const direction = y > this.state.previousScroll ? 'down' : 'up'

        if (direction === 'up' && !this.state.pinned) {
            this.state.pinned = true
            this.element.offsetWidth
            this.element.classList.add(STATUS.PINNED)
        }

        if (direction === 'down' && this.state.pinned) {
            this.state.pinned = false
            this.element.classList.remove(STATUS.PINNED)
        }

        if (y === 0) {
            this.element.classList.remove(STATUS.PINNED)
            this.state.pinned = false
        }

        this.state.previousScroll = y
    }

    handleResize = () => {
        this.resize()
    }

    resize() {
        this.state.height = this.element.getBoundingClientRect().height + 40
    }
}
